























import { Component, Vue } from 'vue-property-decorator';
import { merchantStore } from '@/store/store';
import { dashboardPageName } from '@/router';
import configuration from '@/configuration';

@Component
export default class MSSCompletedPage extends Vue {
  goToDashboard() {
    merchantStore.transitionFromOnboarding();
    this.$router.push({ name: dashboardPageName });
  }

  get companyName(): string {
    return configuration.company.name;
  }

  async mounted() {
    await merchantStore.completeOnboarding();
  }
}
