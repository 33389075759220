
























































































































import CheckIcon from '@/assets/images/icons/resultCheckIcon.svg?inline';
import XIcon from '@/assets/images/icons/xIcon.svg?inline';
import ConfirmWebsite from '@/components/mss/integration/update-website-flow/ConfirmWebsite.vue';
import UpdateWebsiteForm from '@/components/mss/integration/update-website-flow/UpdateWebsiteForm.vue';
import WebsiteUpdated from '@/components/mss/integration/update-website-flow/WebsiteUpdated.vue';
import configuration from '@/configuration';
import { mssIntegrationPageName } from '@/router';
import { applicationStore, merchantStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'x-icon': XIcon,
    'check-icon': CheckIcon,
    'confirm-website': ConfirmWebsite,
    'update-website': UpdateWebsiteForm,
    'update-result': WebsiteUpdated,
  },
})
export default class IntegrationFailure extends Vue {
  supportEmail: string = configuration.supportEmail;
  showConfirm = false;
  showUpdateForm = false;
  showUpdateResult = false;

  get showModal(): boolean {
    return this.showConfirm || this.showUpdateForm || this.showUpdateResult;
  }

  openConfirm() {
    this.showConfirm = true;
  }
  openUpdateForm() {
    this.showConfirm = false;
    this.showUpdateForm = true;
  }
  openUpdateResult() {
    this.showUpdateForm = false;
    this.showUpdateResult = true;
  }
  closeModal(e) {
    if (e) e.preventDefault();
    // Don't close modal if already updated website
    if (this.showUpdateResult) {
      return;
    }
    // Force booleans
    this.showConfirm = this.showUpdateForm = this.showUpdateResult = false;
  }
  goBackToWidgetInstructions() {
    this.goBack('showWidgetStep');
  }

  get isMobile(): boolean {
    return applicationStore.isMobile;
  }

  get companyName(): string {
    return configuration.company.name;
  }

  get websiteUrl(): string {
    return merchantStore.onboardingMerchant?.businessInfo?.website || '';
  }

  get widgetVerified(): boolean {
    return merchantStore.onboardingMerchant?.widgetVerified || false;
  }
  
  get gatewayVerified(): boolean {
    return merchantStore.onboardingMerchant?.integrationVerified || false;
  }

  goBack(step?: 'showGatewayStep' | 'showWidgetStep') {
    const stepParameters = {};
    if (step) stepParameters[step] = true;
    this.$router.push({ name: mssIntegrationPageName, params: stepParameters });
  }
}
