






import ConnectToStripe from '@/components/mss/ConnectToStripe.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'stripe-connect': ConnectToStripe,
  }
})
export default class MSSConnectToStripePage extends Vue {}
