







































import { mssTestingIntegrationPageName } from '@/router';
import { Component, Vue } from 'vue-property-decorator';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import configuration from '@/configuration';

@Component({
  components:{
    'merchant-integration-info': MerchantIntegrationInformation,
  }
})
export default class FallbackIntegration extends Vue {
  loading = false;

  get companyName(): string {
    return configuration.company.name;
  }
  
  goNextStep() {
    this.$router.push({ name: mssTestingIntegrationPageName });
  }
}
