








































































































































































































import ThemesImage from '@/assets/images/mss/shopify-integration/Themes.svg?inline';
import ThemeLiquidImage from '@/assets/images/mss/shopify-integration/ThemeLiquid.svg?inline';
import CodeScreenshotImage from '@/assets/images/mss/shopify-integration/CodeScreenshot.svg?inline';
import WidgetImage from '@/assets/images/mss/shopify-integration/Widget.svg?inline';
import PaymentFormImage from '@/assets/images/mss/shopify-integration/PaymentForm.svg?inline';
import CompleteOrderImage from '@/assets/images/mss/shopify-integration/CompleteOrder.svg?inline';
import MobileNumberImage from '@/assets/images/mss/shopify-integration/MobileNumber.svg?inline';
import SelectAccountImage from '@/assets/images/mss/shopify-integration/SelectAccount.svg?inline';
import EnterIdImage from '@/assets/images/mss/shopify-integration/EnterId.svg?inline';
import EnterTestIdImage from '@/assets/images/mss/shopify-integration/EnterTestId.svg?inline';
import PaymentOptionImage from '@/assets/images/mss/shopify-integration/PaymentOption.png?inline';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import configuration from '@/configuration';
import logger from '@/logger';
import { mdiClockOutline, mdiInformationOutline, mdiTriangle } from '@mdi/js';
import { mssTestingIntegrationPageName } from '@/router';
import { merchantStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ThemesImage,
    ThemeLiquidImage,
    CodeScreenshotImage,
    WidgetImage,
    PaymentFormImage,
    CompleteOrderImage,
    MobileNumberImage,
    SelectAccountImage,
    EnterIdImage,
    PaymentOptionImage,
    EnterTestIdImage,
    'merchant-integration-info': MerchantIntegrationInformation,
  }
})
export default class Integration extends Vue {
  loading = false;
  shouldUseNewVerbiage = true;

  // For some reason v-icon will not accept icons unless they're in a variable
  clock: string = mdiClockOutline;
  info: string = mdiInformationOutline;
  triangle: string = mdiTriangle;
  // Link for integration video
  youtubeLink: string = configuration.links.shopifyIntegrationVideoLink;
  // Code snippet for Quadpay/Zip Widget Lite
  // eslint-disable-next-line
  code: string = "{% comment %} <!-- Insert Zip Widget Lite --> {% endcomment %}\n <script\n src=\"https://widgets.quadpay.com/quadpay-shopify.js\" defer=\"defer\"><\/script>"


  get companyName(): string {
    return configuration.company.name;
  }

  get website(): string {
    const storedWebsite = merchantStore.onboardingMerchant?.businessInfo?.website || '';
    // Add on / if necessary
    return (storedWebsite[storedWebsite.length -1 ] === '/') ? `${storedWebsite}` : `${storedWebsite}/`;
  }

  // Link for changing Shopify theme code
  get themesLink(): string {
    const path = 'admin/themes';
    return this.website + path;
  }
  // Link for Shopify gateway integration
  get alternativeProvidersLink(): string {
    const path = 'admin/settings/payments/alternative-providers/1052742';
    return this.website + path;
  }

  // Track whether steps are expanded or not
  showSteps: any = {
    gateway: false,
    widget: false,
  }

  toggleShowStep(key: string) {
    this.showSteps[key] = !this.showSteps[key];
  }

  copy(event: ClipboardEvent, elementId: string) {
    let elementToCopy = document.getElementById(elementId);
    if (!elementToCopy) return;
    (elementToCopy as any).select();

    try {
      document.execCommand('copy');
      // TODO better UX for successful copy
      logger.info('Copy successful');
    } catch (err) {
      alert(this.$t("shopifyIntegration.unable-copy-alert"));
    }
    // Unselect the range
    (window as any).getSelection().removeAllRanges();
  }
  
  goNextStep() {
    this.$router.push({ name: mssTestingIntegrationPageName });
  }

  async mounted() {
    if (this.$route?.params?.showWidgetStep) {
      await this.toggleShowStep('widget');
      document.getElementById('step-widget-container')?.scrollIntoView();
    } else if (this.$route?.params?.showGatewayStep) {
      await this.toggleShowStep('gateway');
      document.getElementById('step-gateway-container')?.scrollIntoView();
    }
  }
}
