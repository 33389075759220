

























import DialogBox from '@/components/common/DialogBox.vue';
import Magento2Integration from '@/components/mss/integration/Magento2Integration.vue';
import OracleAtgIntegration from '@/components/mss/integration/OracleAtgIntegration.vue';
import SalesforceIntegration from '@/components/mss/integration/SalesforceIntegration.vue';
import ShopifyIntegration from '@/components/mss/integration/ShopifyIntegration.vue';
import SpreeIntegration from '@/components/mss/integration/SpreeIntegration.vue';
import SyliusIntegration from '@/components/mss/integration/SyliusIntegration.vue';
import CartHookIntegration from '@/components/mss/integration/CartHookIntegration.vue';
import WooCommerceIntegration from '@/components/mss/integration/WooCommerceIntegration.vue';
import FallbackIntegration from '@/components/mss/integration/FallbackIntegration.vue';
import { merchantStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import eventBus from '@/event-bus';

@Component({
  components: {
    'dialog-box': DialogBox,
    'magento-integration': Magento2Integration,
    'oracle-atg-integration': OracleAtgIntegration,
    'salesforce-integration': SalesforceIntegration,
    'shopify-integration': ShopifyIntegration,
    'spree-integration': SpreeIntegration,
    'sylius-integration': SyliusIntegration,
    'carthook-integration': CartHookIntegration,
    'woocommerce-integration': WooCommerceIntegration,
    'fallback-integration': FallbackIntegration,
  }
})
export default class MSSIntegrationPage extends Vue {
  showStripeSuccess = false;
  closeDialog() {
    this.showStripeSuccess = false;
  }

  get platform(): string {
    return merchantStore.onboardingMerchant?.businessInfo?.platform || '';
  }

  async mounted() {
    if (this.$route?.params?.showStripeSuccessModal) this.showStripeSuccess = true;
    eventBus.publishIntegrationPageLoadedEvent();
    await merchantStore.getManualApprovalForOnboardingMerchant();
  }
}
