


























import WarningIcon from '@/assets/images/icons/warningIconMedium.svg?inline';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import StripeFailure from '@/components/mss/StripeFailure.vue';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { mssCompletedPageName, mssIntegrationPageName } from '@/router';
import { MssStage } from '@/store/merchant/merchant-models';
import { stripeStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import { merchantStore } from '@/store/store';
import { bigCommerce } from '@/store/merchant/merchant-settings';

@Component({
  components: {
    'loading-spinner': LoadingSpinner,
    'stripe-failure': StripeFailure,
    'warning-image': WarningIcon,
  }
})
export default class MSSAfterStripePage extends Vue {
  loading = true;
  success = false;
  failureReasons = '';

  async callAndHandle(counter: number, intervalId?: NodeJS.Timeout) {
    // Call to PrepareIntegration
    const stripeAccountCompleted = await stripeStore.determineStripeSuccess();

    // Handle
    // If successful, trigger change to start loading Stripe Success component
    this.success = stripeAccountCompleted.success;

    // If successful or we have retried for 2 minutes, settle on result
    if (this.success || counter === 12) {
      if (intervalId) clearInterval(intervalId);
      this.loading = false;
    }

    // Make appropriate tracking calls
    if (this.success) {
      eventBus.publishMssStageCompletedEvent(MssStage.stripeAccountCompleted, counter);
      if (this.platform === bigCommerce.systemName) {
        await this.handleBigCommerceMerchant();
      } else {
        this.$router.push({ name: mssIntegrationPageName, params: { showStripeSuccessModal: 'true' } });
      }
    }
    else if (counter === 12) {
      const failureReasons = stripeAccountCompleted.failureReasons || '';
      this.failureReasons = failureReasons;
      eventBus.publishMssDeclinedEvent(`Stripe decline: ${failureReasons}`);
      stripeStore.updateSFDCStripeFailure(failureReasons);
    }
  }

  get platform(): string {
    return merchantStore.onboardingMerchant?.businessInfo?.platform || '';
  }

  async handleBigCommerceMerchant() {
    await merchantStore.bypassWidgetIntegration();
    await merchantStore.bypassIntegrationTests();

    this.$router.push({ name: mssCompletedPageName });
  }

  // async/await to properly catch errors
  async mounted() {
    // // Attempt prepareIntegration call 13 times before settling on failure
    let counter = 0;

    // First call at 0 seconds
    await this.callAndHandle(counter);

    // Ensure break after first call if successful
    if (this.success) return;

    // Else start intervals (one call every 10 seconds for two minutes)
    const intervalId = setInterval(async () => {
      counter++;
      logger.debug(counter);

      await this.callAndHandle(counter, intervalId);
    }, 10000);
  }
}
