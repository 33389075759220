








import HeaderNotification from '@/components/common/HeaderNotification.vue';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

/**
 * Routing page for Merchant Self Sign Up
 */
@Component({
  components: {
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
    'header-notification': HeaderNotification
  },
})
export default class MSSMainPage extends Vue {}
