



















































































































































































import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import { mdiClockOutline, mdiTriangle } from '@mdi/js';
import { mssTestingIntegrationPageName } from '@/router';
import { Component, Vue } from 'vue-property-decorator';
import configuration from '@/configuration';

@Component({
  components: {
    'merchant-integration-info': MerchantIntegrationInformation,
  },
})
export default class Integration extends Vue {
  loading = false;

  // For some reason v-icon will not accept icons unless they're in a variable
  clock: string = mdiClockOutline;
  triangle: string = mdiTriangle;

  // Track whether steps are expanded or not
  showSteps: any = {
    gateway: false,
    widget: false,
  }

  get companyName(): string {
    return configuration.company.name;
  }

  toggleShowStep(key: string) {
    this.showSteps[key] = !this.showSteps[key];
  }

  goNextStep() {
    this.$router.push({ name: mssTestingIntegrationPageName });
  }
}
