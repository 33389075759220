






import Declined from '@/components/mss/Declined.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'declined': Declined,
  }
})
export default class MSSDeclinedPage extends Vue {}
