













import IntegrationFailure from '@/components/mss/integration/IntegrationFailure.vue';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import { mssCompletedPageName } from '@/router';
import { merchantStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import eventBus from '@/event-bus';
import configuration from '@/configuration';

@Component({
  components: {
    'integration-failure': IntegrationFailure,
    'loading-spinner': LoadingSpinner,
  }
})
export default class MSSTestingIntegrationPage extends Vue {
  loading = true;
  success = false;

  get companyName(): string {
    return configuration.company.name;
  }

  // async/await to properly catch errors
  async mounted() {
    const response = await merchantStore.checkOnboardingIntegration();
    if (response) this.$router.push({ name: mssCompletedPageName });
    this.success = response;
    this.loading = false;
    eventBus.publishIntegrationTestPageLoadedEvent();
  }
}
