


























































































































































































































































































































































import GooglePlacesAutocomplete, { AddressResult } from '@/components/common/GooglePlacesAutocomplete.vue';
import PdfLightbox from '@/components/common/PdfLightbox.vue';
import configuration from '@/configuration';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { mssConnectToStripePageName, mssDeclinedPageName, mssSalesRepPageName } from '@/router';
import intercom from '@/services/intercom';
import { SupportedCountry, Territory } from '@/store/application/application-models';
import { Address, BusinessInfoModel, MsaVersionEnum } from '@/store/merchant/merchant-models';
import * as settings from '@/store/merchant/merchant-settings';
import { applicationStore, authenticationStore, merchantStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'google-places-autocomplete': GooglePlacesAutocomplete,
    'msa-lightbox': PdfLightbox,
  },
})
export default class BusinessInfo extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  valid = false;
  loading = false;
  
  // 0: edit 1: verify
  page = 0;

  fixedPlatform = false;

  showMsaLightbox = false;
  msaSigned = false;
  msaVersion?: MsaVersionEnum = MsaVersionEnum.standard;

  addressValidPlacesSelection = false;
  addressErrorState = false;
  addressErrorMessages: string[] = [];
  addressLine2ErrorMessages: string[] = [];

  addressRules = Array<(v: string) => TranslateResult | boolean>();
  phoneNumberRules = Array<(v: string) => TranslateResult | boolean>();
  validWebsiteRules: Array<(str: string) => TranslateResult | boolean>;
  itemSelectedRules: Array<(str: string) => TranslateResult | boolean>;
  msaRules: Array<(val: boolean) => TranslateResult | boolean>;
  validStringInputRules: Array<(str: string) => TranslateResult | boolean>;
  validEinRules: Array<(str: string) => TranslateResult | boolean>;
  validLegalNameRules: Array<(str: string) => TranslateResult | boolean>;
  
  // v-sliders correspond to indexes rather than the values in items and cannot accurately alter merchantBusinessInfo 
  annualSalesTick = 0; // (v-model) index of annual sales (corresponds to [] in merchSettings)
  annualSales = ''; // string for displaying annual sales to user
  averageOrderValueTick = 0; // (v-model) index/value of aov
  
  merchantBusinessInfo: BusinessInfoModel = {
    firstName: '',
    lastName: '',
    businessName: '',
    legalName: '',
    businessPhoneNumber: '',
    businessAddress: {},
    website: '',
    ein: '',
    annualSalesMin: 0,
    annualSalesMax: 0,
    averageOrderValue: 0,
    platform: '',
    industry: '',
    secondaryIndustry: '',
    howYouHeardAboutQuadpay: '',
  }
  
  get country(): SupportedCountry {
    return applicationStore.currentCountry;
  }

  get companyName(): string {
    return configuration.company.name;
  }

  get isMssEligible(): boolean {
    // AnnualSalesTick (at index) 0 corresponds to T4 (0K-1M)
    // AnnualSalesTick (at index) 1 corresponds to T3 (1M-5M)
    return this.annualSalesTick <= 1;
  }

  get termsLink(): string {
    // MSA terms and conditions
    // TODO get real urls
    let link = this.merchantBusinessInfo.secondaryIndustry !== 'Hair Extensions' ? configuration.links.msaTermsAndConditionsStandardLink : configuration.links.msaTermsAndConditionsHairLink;
    // Add in pdf modifiers for lightbox
    // https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters.pdf
    // https://www.adobe.com/content/dam/acom/en/devnet/pdf/PDF32000_2008.pdf
    link += '#toolbar=0&navpanes=0&view=FitH&scrollbar=0';
    return link;
  }

  get merchSettings(): any {
    return settings;
  }

  get hasSecondaryIndustry(): boolean {
    return this.merchantBusinessInfo.industry
      ? this.merchSettings.industries[this.merchantBusinessInfo.industry].length > 0
      : false;
  }

  get isEinRequired(): boolean {
    const territories = applicationStore.currentCountry?.territories || [];
    return territories.some(t => t === Territory.US);
  }

  /**
   * Callback is used when no results are found from google places autocomplete for the
   * given user input.
   */
  addressNoResultsFound() {
    logger.debug('No address results found');
    this.addressValidPlacesSelection = false;
    this.addressErrorState = true;
    this.addressErrorMessages.push(this.$t('businessInfo.validation-address-invalid').toString());
  }

  /**
   * Handles all address changes.  Additional validation takes places here in addition
   * to the rules defined on the component as to verify that the address is in a country we support
   * and that all address components are populated.
   */
  addressChanged(addressData: AddressResult, placeResultData: google.maps.places.PlaceResult, id: string) {
    logger.debug('Address changed', addressData, placeResultData, id);
    const supportedCountries = applicationStore.supportedCountries.map((country) => country.code);

    // Clear error state on any input change
    this.addressValidPlacesSelection = true;
    this.addressErrorState = false;
    this.addressErrorMessages = [];

    // We can ignore as the user might still be typing
    if (!addressData) {
      return;
    }

    // Google often returns addresses with no street number, so specifically set a message for that case
    if (!addressData.street_number || !addressData.route) {
      this.addressErrorState = true;
      this.addressErrorMessages.push(this.$t('businessInfo.validation-address-no-street-number').toString());

    } else if (addressData.country && !supportedCountries.includes(addressData.country)) {
      this.addressErrorState = true;
      this.addressErrorMessages.push(
        this.$t('businessInfo.validation-address-invalid-country',
          {
            supportedCountries: supportedCountries.join(', '),
            companyName: this.companyName,
          })
          .toString());

    } else if (!addressData.route // Street
      || !(addressData.locality || addressData.sublocality || addressData.neighborhood || addressData.postal_town) // City
      || !addressData.administrative_area_level_1 // State
      || !addressData.postal_code
      || !addressData.country) {
      this.addressErrorState = true;
      this.addressErrorMessages.push(this.$t('businessInfo.validation-address-invalid').toString());

      // Address is valid
    } else {
      if (!this.merchantBusinessInfo.businessAddress) {
        this.merchantBusinessInfo.businessAddress = {};
      }

      this.merchantBusinessInfo.businessAddress.state = addressData.administrative_area_level_1;
      // Sometimes the city is only in the sublocality in metropolitan cities, among other variances by territory
      this.merchantBusinessInfo.businessAddress.city = addressData.locality || addressData.sublocality || addressData.neighborhood || addressData.postal_town;
      this.merchantBusinessInfo.businessAddress.line1 = addressData.street_number + ' ' + addressData.route;
      this.merchantBusinessInfo.businessAddress.postCode = addressData.postal_code;
      this.merchantBusinessInfo.businessAddress.country = addressData.country;
    }
  }

  formattedCityStateZip(address: Address): string {
    const { city, state, postCode } = address;
    return `${city}, ${state} ${postCode}`
  }

  // Every time the user switches the primary industry, reset secondary
  onIndustryChange() {
    this.merchantBusinessInfo.secondaryIndustry = '';
    this.msaVersion = MsaVersionEnum.standard;
    eventBus.publishTrackActionEvent('MP MSS Primary Industry Change', { industry: this.merchantBusinessInfo.industry, merchantId: authenticationStore.merchantId });
  }
  onSecondaryIndustryChange() {
    if (this.merchantBusinessInfo.secondaryIndustry === 'Hair Extensions') this.msaVersion = MsaVersionEnum.hair;
    eventBus.publishTrackActionEvent('MP MSS Secondary Industry Change', { industry: this.merchantBusinessInfo.secondaryIndustry, merchantId: authenticationStore.merchantId });
  }

  trackClick() {
    this.toggleMsaLightbox(true);
    eventBus.publishTrackClickEvent('MSA terms', { msaVersion: this.msaVersion });
  }

  toggleMsaLightbox(bool: boolean) {
    this.showMsaLightbox = bool;
  }

  /**
   * Return to editing business info
   */
  goEdit() {
    if (this.page === 1) this.page--;
  }

  /**
   * Validate and set all business info
   * then continue to verifying business info
   */
  goVerify(event: Event) {
    event.preventDefault();
    if (this.page === 1) return;

    // Set business info monetary values & annualSales display string based on slider ticks
    this.annualSales = this.merchSettings.annualSalesTicks[this.annualSalesTick];
    this.merchantBusinessInfo.averageOrderValue = this.averageOrderValueTick;

    const [min, max] = this.merchSettings.annualSalesMinsAndMaxes[this.annualSalesTick];
    this.merchantBusinessInfo.annualSalesMin = min;
    this.merchantBusinessInfo.annualSalesMax = max;

    this.valid = this.$refs.form.validate();
    if (!this.valid) {
      return
    }

    this.page++;
    // Scroll to top
    window.scrollTo(0, 0);
  }

  /**
   * Submit verified business info
   */
  async submitBusinessInfo(event: Event) {
    event.preventDefault();

    // UI idempotency so a user doesn't submit a form that's already been submitted
    if (this.loading) {
      return;
    }
    this.loading = true;

    // Regardless of tier, if unsupported industry, do not submit business info, do not advance stage
    if (this.merchSettings.unsupportedSecondaryIndustries.includes(this.merchantBusinessInfo.secondaryIndustry)) {
      // Log what merchants are trying to use us but cannot because of secondary industry
      eventBus.publishMssDeclinedEvent(`Industry Declined ${this.merchantBusinessInfo.secondaryIndustry}`);
      return this.$router.push({ name: mssDeclinedPageName });
    }

    // If Tier 3/4 and unsupported platform, do not submit business info, do not advance stage
    // Move to general decline screen, upon relogin, the user will be able to try submitting again (maybe one day our BC integration will be easy)
    if (this.isMssEligible && !this.merchSettings.isPlatformSupported(this.merchantBusinessInfo.platform)) {
      // Log what merchants are trying to use us but cannot because of platform
      eventBus.publishMssDeclinedEvent(`Platform Declined ${this.merchantBusinessInfo.platform}`);
      return this.$router.push({ name: mssDeclinedPageName });
    }

    // Make sure MSA agreement is false for non Tier 3/4
    if (!this.isMssEligible) {
      this.msaSigned = false;
      this.msaVersion = undefined;
    }

    const businessInfoCommand: BusinessInfoModel = { ...this.merchantBusinessInfo, msaSigned: this.msaSigned, msaVersion: this.msaVersion, merchantId: authenticationStore.merchantId };
    eventBus.publishFormSubmissionEvent('MP MSS Business Info', this.valid, [authenticationStore.merchantId, this.merchantBusinessInfo.platform, this.merchantBusinessInfo.industry, this.merchantBusinessInfo.website]);
    await merchantStore.submitOnboardingBusinessInfo(businessInfoCommand);

    if (configuration.featureFlags.intercom) {
      await intercom.updateSettings(businessInfoCommand.firstName || '', businessInfoCommand.lastName || '');
    }

    // if successful Tier 3/4 (platform supported, msa is signed), continue to Stripe Connect
    if (this.isMssEligible) this.$router.push({ name: mssConnectToStripePageName });
    // else (if Tier Ent/1/2) a sales rep will reach out
    else {
      this.$router.push({ name: mssSalesRepPageName });
    }
  }

  /**
   * Initialize all validation rules for the component.
   *
   * Rules check if the supplied field is valid.  If not, the localized messages
   * is returned and displayed via Vuetify's validation framework.
   */
  initializeValidationRules() {
    // Additional validation in this.addressChanged() method.
    this.addressRules = [
      (str: string) => this.validateAddressPopulated(str) || this.$t('businessInfo.validation-address-required'),
    ];
    this.phoneNumberRules = [
      (str: string) => this.isNotEmptyString(str) || this.$t('businessInfo.validation-required'),
      (str: string) => this.containsCorrectNumberOfDigits(str) || this.$t('businessInfo.validation-phone-number-incorrect-length', { min: applicationStore.currentCountry.minPhoneNumberLength, max: applicationStore.currentCountry.maxPhoneNumberLength }),
    ];
    this.validWebsiteRules = [
      (str: string) => this.isNotEmptyString(str) || this.$t('businessInfo.validation-required'),
      (str: string) => this.isLessThanMaxCharacters(str, 100) || this.$t('businessInfo.validation-character-max', [ 100 ]),
      (str: string) => this.containsTLD(str) || this.$t('businessInfo.validation-website-tld-required'),
      (str: string) => !this.containsAmpersand(str) || this.$t('businessInfo.validation-website-illegal-character'),
    ];
    this.itemSelectedRules = [
      (str: string) => this.isNotEmptyString(str) || this.$t('businessInfo.validation-required'),
    ];
    this.msaRules = [
      (val: boolean) => !!val || this.$t('businessInfo.validation-msa-required', { companyName: this.companyName }),
    ];
    this.validStringInputRules = [
      (str: string) => this.isNotEmptyString(str) || this.$t('businessInfo.validation-required'),
      (str: string) => this.isLessThanMaxCharacters(str, 100) || this.$t('businessInfo.validation-character-max', [ 100 ]),
    ];
    this.validEinRules = this.isEinRequired ? [
      (str: string) => this.isNotEmptyString(str) || this.$t('businessInfo.validation-required'),
      (str: string) => this.isLessThanMaxCharacters(str, 10) || this.$t('businessInfo.validation-character-max', [ 10 ]),
      (str: string) => this.isValidEinString(str) || this.$t('businessInfo.validation-invalid-ein'),
    ] : [
      () => true
    ];
    this.validLegalNameRules = [
      (str: string) => this.isValidLegalName(str) || this.$t('businessInfo.validation-legal-name-invalid'),
    ];
  }

  /**
   * Validates that the address is populated
   */
  validateAddressPopulated(address: string): boolean {
    return !!address;
  }
  isNotEmptyString = (str: string): boolean => {
    return !!str && str.trim().length > 0;
  }
  containsCorrectNumberOfDigits = (str: string): boolean => {
    return applicationStore.currentCountry.minPhoneNumberLength <= str.length && str.length <= applicationStore.currentCountry.maxPhoneNumberLength;
  }
  isLessThanMaxCharacters = (str: string, max: number): boolean => {
    return !!str && str.trim().length <= max;
  }
  containsTLD = (str: string): boolean => {
    return /\..+/.test(str);
  }
  containsAmpersand = (str: string): boolean => {
    return /@/g.test(str);
  }
  isValidEinString = (str: string): boolean => {
    /*
      This pattern verifies that the first two digits are not in the exclusion list, followed by a
      hyphen, then finally 7 additional digits. There are other considerations, such as an SSN as
      the EIN, which is not currently accepted in our pattern. Additionally, there may be a single
      character suffix that denotes what type of employer they are. That is also not considered in
      this pattern checking.

      https://secure.ssa.gov/apps10/poms.nsf/lnx/0101103015#b
    */
    return /^(?!(?:07|08|09|17|18|19|28|29|49|69|70|78|79|89|96|97))\d{2}-\d{7}$/.test(str);
  }
  isValidLegalName = (str: string): boolean => {
    return !!str && str.trim().length > 0 && /^[a-zA-Z0-9 ]*$/.test(str.trim());
  }

  /**
   * Must initialize with i18n
   */
  created() {
    this.initializeValidationRules();
  }

  mounted() {
    if (merchantStore.platform) {
      // If platform was set upon sign-up, do not allow user to alter
      this.merchantBusinessInfo.platform = merchantStore.platform;
      this.fixedPlatform = true;
    }
  }
}
