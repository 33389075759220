






import BusinessInfo from '@/components/mss/BusinessInfoSfdcSignUp.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'business-info': BusinessInfo,
  }
})
export default class MSSBusinessInfoSfdcSignUpPage extends Vue {}
