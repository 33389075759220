











import CheckImage from '@/assets/images/check.svg?inline';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'check-image': CheckImage,
  },
})
export default class SalesRep extends Vue {}
