




















































import CloseIcon from '@/assets/images/icons/xCloseIcon.svg?inline';
import { merchantStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'close-icon': CloseIcon,
  }
})
export default class UpdateWebsiteForm extends Vue {
  @Prop({ required: true })
  closeModalFunction: () => void;

  @Prop({ required: true })
  openUpdateResultFunction: () => void;

  $refs!: {
    form: HTMLFormElement;
  };

  loading = false;
  valid = false;
  website = '';
  validStringInputRules: Array<(str: string) => TranslateResult | boolean>;

  async updateWebsite(e: Event) {
    if (e) e.preventDefault();

    if (this.loading) return;

    this.valid = this.$refs.form.validate();
    if (!this.valid) return;

    this.loading = true;
    await merchantStore.updateWebsiteUrl(this.website);

    this.openUpdateResultFunction();
  }

  /**
   * Initialize all validation rules for the component.
   *
   * Rules check if the supplied field is valid.  If not, the localized messages
   * is returned and displayed via Vuetify's validation framework.
   */
  initializeValidationRules() {
    this.validStringInputRules = [
      (str: string) => this.isNotEmptyString(str) || this.$t('updateWebsiteForm.validation-required'),
      (str: string) => this.isLessThanMaxCharacters(str, 100) || this.$t('updateWebsiteForm.validation-character-max', [ 100 ]),
    ];
  }

  isNotEmptyString = (str: string): boolean => {
    return !!str && str.trim().length > 0;
  }
  isLessThanMaxCharacters = (str: string, max: number): boolean => {
    return !!str && str.trim().length <= max;
  }

  /**
   * Must initialize with i18n
   */
  created() {
    this.initializeValidationRules();
  }
}
