





















































import CheckIcon from '@/assets/images/icons/checkIcon2.svg?inline';
import ConnectImage from '@/assets/images/connect-to-stripe.svg?inline';
import eventBus from '@/event-bus';
import { authenticationStore, stripeStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import configuration from '@/configuration';

@Component({
  components: {
    'connect-image': ConnectImage,
    'check-icon': CheckIcon,
  },
})
export default class ConnectToStripe extends Vue {
  loading = false;
  redirectLink = '';
  shouldUseNewVerbiage = true;

  get companyName(): string {
    return configuration.company.name;
  }

  async goToStripe() {
    if (!this.redirectLink) throw new Error('No Stripe Connect link');
    eventBus.publishStartedStripeEvent();
    window.location.href = this.redirectLink;
  }

  // Logout and redirect to logout page
  async logout() {
    await authenticationStore.logout(false);
  }

  async mounted() {
    this.redirectLink = await stripeStore.getStripeConnectLink();
    this.loading = false;
  }
}
