






import SalesRep from '@/components/mss/SalesRep.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'sales-rep': SalesRep,
  }
})
export default class MSSSalesRepPage extends Vue {}
