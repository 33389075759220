



























































































































































import HoodieImage from '@/assets/images/mss/woocommerce-integration/Hoodie.svg?inline';
import CheckoutImage from '@/assets/images/mss/woocommerce-integration/Checkout.svg?inline';
import ManageImage from '@/assets/images/mss/woocommerce-integration/Manage.svg?inline';
import PluginsImage from '@/assets/images/mss/woocommerce-integration/Plugins.svg?inline';
import AddPluginImage from '@/assets/images/mss/woocommerce-integration/AddPlugin.svg?inline';
import UploadPluginImage from '@/assets/images/mss/woocommerce-integration/UploadPlugin.svg?inline';
import ChoosePluginImage from '@/assets/images/mss/woocommerce-integration/ChoosePlugin.svg?inline';
import ActivatePluginImage from '@/assets/images/mss/woocommerce-integration/ActivatePlugin.svg?inline';
import PluginSettingsImage from '@/assets/images/mss/woocommerce-integration/PluginSettings.svg?inline';
import WidgetCustomizationImage from '@/assets/images/mss/woocommerce-integration/WidgetCustomization.svg?inline';
import IntegrationGatewayImage from '@/assets/images/mss/woocommerce-integration/IntegrationGateway.svg?inline';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import { mdiClockOutline, mdiTriangle } from '@mdi/js';
import { mssTestingIntegrationPageName } from '@/router';
import { Component, Vue } from 'vue-property-decorator';
import { applicationStore } from '@/store/store';
import configuration from '@/configuration';

@Component({
  components: {
    HoodieImage,
    CheckoutImage,
    ManageImage,
    PluginsImage,
    AddPluginImage,
    UploadPluginImage,
    ChoosePluginImage,
    ActivatePluginImage,
    PluginSettingsImage,
    WidgetCustomizationImage,
    IntegrationGatewayImage,
    'merchant-integration-info': MerchantIntegrationInformation,
  },
})

export default class Integration extends Vue {
  loading = false;

  // For some reason v-icon will not accept icons unless they're in a variable
  clock: string = mdiClockOutline;
  triangle: string = mdiTriangle;

  // Track whether steps are expanded or not
  showSteps: any = {
    gateway: false,
    widget: false,
  }

  get companyName(): string {
    return configuration.company.name;
  }

  get currencySymbol(): string {
    return applicationStore.currentCountry.currencySymbol;
  }

  get defaultMinTransaction(): string {
    return applicationStore.currentCountry.defaultMinTransaction;
  }

  get defaultMaxTransaction(): string {
    return applicationStore.currentCountry.defaultMaxTransaction;
  }

  toggleShowStep(key: string) {
    this.showSteps[key] = !this.showSteps[key];
  }
  
  goNextStep() {
    this.$router.push({ name: mssTestingIntegrationPageName });
  }

  async mounted() {
    if (this.$route?.params?.showWidgetStep) {
      await this.toggleShowStep('widget');
      document.getElementById('step-widget-container')?.scrollIntoView();
    } else if (this.$route?.params?.showGatewayStep) {
      await this.toggleShowStep('gateway');
      document.getElementById('step-gateway-container')?.scrollIntoView();
    }
  }
}
