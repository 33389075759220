








import configuration from '@/configuration';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Declined extends Vue {
  get companyName(): string {
    return configuration.company.name;
  }
}
