





























import configuration from '@/configuration';
import logger from '@/logger';
import { applicationStore } from '@/store/store';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export const ScriptLoadedEvent = 'googlePlacesAutoComplete:gmapsApiLoaded';
export const ComponentReadyEvent = 'googlePlacesAutocomplete:ready';
export const GoogleMapsScriptUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places';

/**
 * Encapsulates an address returned from Google Places
 * 
 * https://developers.google.com/maps/documentation/geocoding/overview#Types
 */
export interface AddressResult {
  street_number?: string;
  route?: string;
  locality?: string;
  sublocality?: string;
  administrative_area_level_1?: string;
  country?: string;
  postal_code?: string;
  neighborhood?: string;
  postal_town?: string;
}
// Constants
const PlacesFields: string[] = ['address_component', 'formatted_address', 'geometry', 'name', 'place_id', 'type', 'vicinity'];

/**
 * This component is used to get an address result from google's autocomplete
 * widget within the Vue/Vuetify frameworks.  This component is inspired by
 * https://github.com/MadimetjaShika/vuetify-google-autocomplete.
 */
@Component
export default class GooglePlacesAutocomplete extends Vue {

  // Properties

  /**
   * Used to translate google places results to a strongly typed object.
   */
  @Prop({
    default: () => ({
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
      neighborhood: 'long_name',
      postal_town: 'long_name',
    }),
  })
  addressComponents: AddressResult;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  autofocus: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop({
    default: 'street-address',
  })
  browserAutocomplete: string;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  clearable: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  disabled: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  error: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop({
    default: () => [],
  })
  errorMessages: string[];

  /**
   * Maps directly to Vuetify
   */
  @Prop({
    required: true,
  })
  id: string;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  label: string;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  placeholder: string;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  readonly: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  required: boolean;

  /**
   * Maps directly to Vuetify
   */
  @Prop({
    default: () => [],
  })
  rules: string[];

  /**
   * Types of responses supported by the places results.
   * Defaults to 'address' only.
   */
  @Prop({
    default: 'address',
  })
  types: string;

  /**
   * Maps directly to Vuetify
   */
  @Prop()
  validateOnBlur: boolean;

  /**
   * The version of the google autocomplete widget to load
   */
  @Prop()
  version: string;

  /**
   * The Vue instance used to publish the ready event for consumers of this component.
   * If none is provided, the root component of Vue is used.
   */
  @Prop()
  eventBus?: Vue;

  /**
   * This is used to override the browser autofill with the google autocomplete widget
   * as Google forces it's availability.  Setting this to true uses a hack
   * to disable it.
   */
  @Prop()
  disableBrowserAutofill: boolean;

  /**
   * Indicates whether or place selection is needed.  If true
   * and the text indicates the user just has free-form text, then
   * the no places result event is emitted.
   */
  @Prop({
    default: true,
  })
  requirePlaceSelection: boolean;

  /**
   * This is admittedly a hack.  Because the place change handler happens in an event
   * handler, it's basically async and comes after the blur event.  This means that in the event
   * a user selects a valid place, the no results found event will still fire.  This delay
   * is the amount of time in milliseconds we wait to check if there is no place selected
   * and if not, still emit the no results found event after that time.
   */
  @Prop({
    default: 200,
  })
  blurNoPlacesDelayInMs: number;

  @Prop()
  focusProp: (...args: any) => void;

  // Data
  $refs!: {
    autocomplete: Vue,
  };
  autocomplete: google.maps.places.Autocomplete;
  autocompleteService: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  autocompleteText = '';
  scriptLoaded = false;
  googlePlacesReady = false;
  placeSelected = false;

  /**
   * The API key used for the the google autocomplete and places services.
   */
  get apiKey(): string {
    return configuration.google.mapsAutocompleteApiKey;
  }

  /**
   * Can be used to restrict the places auto complete to up to 5 countries.
   * Each country string  needs to match the 2 characters ISO 3166 country code.
   * https://developers.google.com/maps/documentation/javascript/places-autocomplete
   */
  get supportedCountryCodes() {
    return applicationStore.supportedCountries.map((item) => item.code);
  }

  /**
   * Used to set the language of the loaded Google autocomplete widget.
   * https://developers.google.com/maps/documentation/javascript/localization
   */
  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  // Methods
  get inputElement(): HTMLInputElement {
    return this.$refs.autocomplete.$refs.input as HTMLInputElement;
  }

  onBlur(): void {
    this.$emit('blur');

    // Because the place selected may be set asynchronously, wait a bit before emitting the no-results-found event
    setTimeout(() => {
      if (!this.placeSelected) {
        this.$emit('no-results-found');
      }
    }, this.blurNoPlacesDelayInMs);
  }

  onFocus(): void {
    this.$emit('focus');
    if (this.focusProp) {
      this.focusProp();
    }
  }

  onInput(value: string): void {
    this.placeSelected = false;
    if (value) {
      this.autocompleteText = value;
      this.$emit('input', value);
    } else {
      // clear was pressed, reset this
      this.autocompleteText = '';
      this.$emit('placechanged', undefined);
    }
  }

  clear(): void {
    this.autocompleteText = '';
  }

  focus(): void {
    this.inputElement.focus();
  }

  blur(): void {
    this.inputElement.blur();
  }

  update(value: string): void {
    this.autocompleteText = value;
  }

  /**
   * Handle a place change from the google maps palce.
   */
  handlePlaceChange(place: google.maps.places.PlaceResult) {
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place);
        return;
      }

      const returnData: AddressResult = {};

      if (place.formatted_address !== undefined) {
        this.autocompleteText = place.formatted_address;
      }

      // Map the address components to a strongly-typed interface
      if (place.address_components !== undefined) {
        // Get each component of the address from the place details
        for (const addressComponent of place.address_components) {
          for (const addressComponentType of addressComponent.types) {
            if (this.addressComponents[addressComponentType]) {
              const val = addressComponent[this.addressComponents[addressComponentType]];
              returnData[addressComponentType] = val;
            }
          }
        }

        this.placeSelected = true;
        this.$emit('placechanged', returnData, place, this.id);
      }
  }

  /**
   * Setup the google places autocomplete widget and associated services
   */
  setupGooglePlacesServices() {
    if (this.autocompleteService && this.autocomplete && this.placesService) {
      this.googlePlacesReady = true;
      return;
    }

    // Check if the script has been loaded
    if (!((window as any).google &&
      typeof (window as any).google === 'object' &&
      typeof (window as any).google.maps === 'object' &&
      typeof (window as any).google.maps.places === 'object')) {

      logger.warn('Unable to setup google places service because script never loaded');
      return;
    }
    const options: google.maps.places.AutocompleteOptions = {
      fields: PlacesFields,
    };

    if (this.types) {
      options.types = [this.types];
    }

    options.componentRestrictions = {
      country: this.supportedCountryCodes,
    };

    this.autocompleteService = new google.maps.places.AutocompleteService();

    const attrContainer = document.getElementById('qp-placesServicesAttrContainer') as HTMLDivElement;
    this.placesService = new google.maps.places.PlacesService(attrContainer);

    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputElement,
      options,
    );

    this.googlePlacesReady = true;

    // Override google's placeholder on the input
    (this.$refs.autocomplete.$refs.input as HTMLInputElement)
      .setAttribute('placeholder', this.placeholder ? this.placeholder : '');

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.handlePlaceChange(place);
    });
  }

  /**
   * Load the google maps scripts from Google.
   */
  async loadGoogleMapsScript() {
    try {

      // Check if the script has already been loaded
      if ((window as any).google &&
        typeof (window as any).google === 'object' &&
        typeof (window as any).google.maps === 'object') {

        if (typeof (window as any).google.maps.places === 'object') {
          this.scriptLoaded = true;
          this.$emit(ScriptLoadedEvent);
          return;
        }

        throw new Error('Google is already loaded, but does not contain the places API.');
      }

      if (!this.scriptLoaded) {

        if (!this.apiKey) {
          throw new Error('Missing Google Maps API Key');
        }

        let url = `${GoogleMapsScriptUrl}&key=${encodeURIComponent(this.apiKey)}`;

        if (this.version) {
          url = `${url}&v=${encodeURIComponent(this.version)}`;
        }

        if (this.currentLocale) {
          url = `${url}&language=${encodeURIComponent(this.currentLocale)}`;
        }

        // Last guard to make sure we don't load again
        if (!this.scriptLoaded) {
          await this.$loadScript(url);
          this.scriptLoaded = true;
          this.$emit(ScriptLoadedEvent);
        }

      }

    } catch (exception) {
      logger.error('Error loading google maps autocomplete', exception);
      throw exception;
    }
  }

  created() {
    this.autocompleteText = this.autocompleteText || '';
  }

  /**
   * Because the scripts are loaded async, setup event subscribers to initialize
   * the widget after the external script is loaded.
   */
  mounted() {
    this.$on(ScriptLoadedEvent, () => {
        this.setupGooglePlacesServices();

        if (this.disableBrowserAutofill) {
          this.overrideGoogleAutoCompleteAttribute();
        }

        Vue.nextTick(() => {
          if (this.eventBus) {
              this.eventBus.$emit(ComponentReadyEvent);
            } else {
              this.$root.$emit(ComponentReadyEvent);
            }
        });
    });

    // We don't await this on purpose as we rely on events to establish the 'ready' state
    this.loadGoogleMapsScript();

  }

  /**
   * Naturally, google's autofill ignores the autocomplete="off" attribute when using Chrome.  However,
   * regardless of what we set it in the <template>, Google's autocomplete widget automatically
   * overrides that value back to 'off'.  This observer catches any changes the Google autocomplete
   * widget makes so we can force it back to 'disabled' as to require the user to enter their address
   * and not autofill it.
   */
  overrideGoogleAutoCompleteAttribute() {
    const autocompleteInput = (this.$refs.autocomplete.$el.querySelector('input') as HTMLInputElement);
    const observerHack = new MutationObserver(() => {
      observerHack.disconnect();
      autocompleteInput.setAttribute('autocomplete', 'disabled');
    });
    observerHack.observe(autocompleteInput, {
      attributes: true,
      attributeFilter: ['autocomplete'],
    });
  }

  // Watchers
  @Watch('autocompleteText')
  onAutocompleteTextChanged(newVal: string): void {
    this.$emit('input', newVal || '');
  }

  @Watch('types')
  onTypesChanged(newVal: string): void {
    if (newVal) {
      this.autocomplete.setTypes([this.types]);
    }
  }
}
