

























import ErrorImage from '@/assets/images/error.svg?inline';
import { mssConnectToStripePageName } from '@/router';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'error-image': ErrorImage
  },
})
export default class StripeFailure extends Vue {
  @Prop({ default: '' })
  failureReasons: string;

  loading = false;

  get displayFailureReason(): boolean {
    return /bank account/.test(this.failureReasons);
  }

  /**
   * Return to the stripe verification page to attempt the action again.
   */
  tryAgain(event: Event) {
    event.preventDefault();

    // UI idempotency in case a user clicks more than once
    if (this.loading) return;

    this.loading = true;
    this.$router.push({ name: mssConnectToStripePageName });
  }
}
