




























import CheckImage from '@/assets/images/check.svg?inline';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'check-image': CheckImage,
  },
})
export default class WebsiteUpdated extends Vue {
  goTest() {
    this.$router.go(0);
  }
}
