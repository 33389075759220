








































import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import configuration from '@/configuration';
import { mssTestingIntegrationPageName } from '@/router';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'merchant-integration-info': MerchantIntegrationInformation,
  }
})
export default class Integration extends Vue {
  loading = false;

  get companyName(): string {
    return configuration.company.name;
  }
  
  goNextStep() {
    this.$router.push({ name: mssTestingIntegrationPageName });
  }
}
