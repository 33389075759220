





































import ErrorImage from '@/assets/images/error.svg?inline';
import CloseIcon from '@/assets/images/icons/xCloseIcon.svg?inline';
import { merchantStore } from '@/store/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'close-icon': CloseIcon,
    'error-image': ErrorImage,
  }
})
export default class ConfirmWebsite extends Vue {
  @Prop({ required: true })
  closeModalFunction: () => void;

  @Prop({ required: true })
  goBackFunction: () => void;

  @Prop({ required: true })
  openUpdateFormFunction: () => void;

  get website(): string {
    return merchantStore.onboardingMerchant.businessInfo?.website || '';
  }
}
