






















import { mdiCloseCircleOutline } from '@mdi/js';
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component
export default class PdfLightbox extends Vue {
  @Prop({ required: true })
  show: boolean;

  @Prop({ required: true })
  src: string;

  @Prop({ required: false, default: '' })
  iframeId: string;

  // Pdf Lightbox uses a close function instead of on, attrs because
  // it can be toggled based on a click or a page turn
  @Prop({ required: true })
  closeLightbox: (bool: boolean) => void;

  // For some reason v-icon will not accept icons unless they're in a variable
  closeIcon: string = mdiCloseCircleOutline;

  handleOutsideClick(e) {
    e.preventDefault();
    // Force toggle close
    this.closeLightbox(false);
  }
}
